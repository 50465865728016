import React, { FC, useContext, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Media
  } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import AuthStore from '../../../stores/AuthStore';
import SearchStore from '../../../stores/SearchStore';
import AuthService from '../../../services/AuthService';
import './AdminNavbarComponent.css';

const AdminNavbarComponent: FC = () => {

    const authStore = useContext(AuthStore);
    const searchStore = useContext(SearchStore);
    const authService = new AuthService();
    const history = useHistory()

    const logout = () => {
      authStore.logout();
      history.push('/login');
    }

    const setSearch = (e: ChangeEvent<HTMLInputElement>) => {
      searchStore.setSearch(e.currentTarget.value);
    }

    return (
        <Navbar className=" navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {'ΠΛΗΡΟΦΟΡΙΑΚΟ ΣΥΣΤΗΜΑ ΥΠΟΒΟΛΗΣ ΠΡΟΤΑΣΕΩΝ ΣΤΑ ΠΡΟΓΡΑΜΜΑΤΑ ΤΟΥ ΠΡΑΣΙΝΟΥ ΤΑΜΕΙΟΥ'}
            </Link>
            <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" onChange = {setSearch} />
                </InputGroup>
              </FormGroup>
            </Form>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt=""
                        src={authStore.user?.photo ? authStore.user?.photo : ''}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {authStore.user?.displayName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  
                  <DropdownItem divider />
                  <DropdownItem onClick={logout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
    )
};

export default observer(AdminNavbarComponent);