import React, { FC, Fragment, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import SidebarComponent from './SidebarComponent/SidebarComponent';
import AdminNavbarComponent from './AdminNavbarComponent/AdminNavbarComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import WorkflowsComponent from './WorkflowsComponent/WorkflowsComponent';
import WorkflowComponent from './WorkflowComponent/WorkflowComponent';
import MyWorkflowsComponent from './MyWorkflowsComponent/MyWorkflowsComponent';
import MyWorkflowComponent from './MyWorkflowComponent/MyWorkflowComponent';
import AuthStore from '../../stores/AuthStore';
import UsersComponent from './UsersComponent/UsersComponent';
import UserComponent from './UserComponent/UserComponent';
import OldApplications from './OldApplications/OldApplications';

const AdminDashboardComponent : FC = () => {
    const authStore = useContext(AuthStore);
    const history = useHistory();
    useEffect(() => {
        if(!authStore.user){
            history.push('/login');
        }
    }, [])
    return (
        <Fragment>
                <SidebarComponent></SidebarComponent>
                
                <div className="main-content" >
                <AdminNavbarComponent
                />
                <Container fluid style={{minHeight: '75vh', paddingTop: '20px'}}>
                    <Switch>
                        <Route path="/myworkflows/new" component={MyWorkflowComponent} />
                        <Route path="/myworkflows/:id" component={MyWorkflowComponent} />
                        <Route path="/myworkflows" component={MyWorkflowsComponent} />
                        <Route path="/oldpropositions" component={OldApplications} />

                        <Route path="/users/new" component={UserComponent} />
                        <Route path="/users/:email" component={UserComponent} />
                        <Route path="/users" component={UsersComponent} />

                        <Route path="/workflows" component={WorkflowsComponent} />
                        <Route path="/workflow/new" component={WorkflowComponent} />
                        <Route path="/workflow/:id" component={WorkflowComponent} />
                        
                        <Route path="/" component={!authStore.user?.roles.includes('Municipality') ? WorkflowsComponent : MyWorkflowsComponent} />
                    </Switch>
                </Container>
                <Container fluid className="mt-auto" style={{maxWidth: '100%'}}>
                    <FooterComponent />
                </Container> 
                </div>
        </Fragment>);
}

export default observer(AdminDashboardComponent)