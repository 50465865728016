import axios, { AxiosRequestConfig } from 'axios';

const api = axios.create({
    baseURL: 'https://demo-backend.intelliflow.gr/api/'
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.authorization = 'Bearer ' + localStorage.getItem('iflow_auth_token');
    return config;
});

export default api;
