import { observable, action } from 'mobx';
import { createContext } from 'react';

class SearchWorkflowsStore{

    @observable search: string = '';
    @observable searchWorkflow = '';
    @observable orderBy: string = 'serialNumber';
    @observable orderDir: string = 'desc'
    @observable page: number = 1;
    @observable perPage: number = 10;

    @action setSearch(s: string) {
        this.search = s;
    }

    @action setSearchWorkflow(sw: string) {
        this.searchWorkflow = sw;
    }

    @action setOrderBy(ob: string) {
        this.orderBy = ob;
    }

    @action setOrderDir(od: string) {
        this.orderDir = od ? od : 'asc';
    }

    @action setPage(p: number) {
        this.page = p;
    }

    @action setPerPage(pp: number) {
        console.log(pp);
        this.perPage = pp;
    }

}

export default createContext(new SearchWorkflowsStore());