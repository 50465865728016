import React, { FC, useContext, useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Card, CardHeader, Form, CardBody, CardFooter, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import AuthStore from '../../../stores/AuthStore';
import IUser from '../../../entities/User';
import { useHistory } from 'react-router';

const UserComponent: FC = () => {

    const authService = new AuthService();
    const authStore = useContext(AuthStore);
    const [user, setuser] = useState<IUser>({
        displayName: '',
        email: '',
        newemail: '',
        photo: '',
        roles: [''],
        token: ''
    });
    const { email } = useParams<{email: string}>();
    const [passwordconfirm, setPasswordconfirm] = useState('');
    const [password, setpassword] = useState('');
    const [successmessage, setSuccessmessage] = useState('');
    const [errormessage, setErrormessage] = useState('');
    const [newuser, setnewuser] = useState(true);
    const history = useHistory();

    useEffect(() => {
        authService.getUsers().then((data: IUser[]) => {
            authStore.setUsers(data);
            const fitting = authStore.getUsers.filter((u: IUser) => u.email === email)
            if(fitting && fitting[0]){
                setuser(fitting[0]);
                setnewuser(false);
            }
        });
        if(authStore.user?.roles.includes('Administrator')){
            authService.getRoles().then(roles => {
                authStore.setRoles(roles);
                console.log(roles);
            });
        }
    }, [authStore]);

    const setUserDisplayName = (e: ChangeEvent<HTMLInputElement>) => {
        setuser({
            ...user,
            displayName: e.currentTarget.value
        } as IUser);
    };

    const setUserEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setuser({
            ...user,
            newemail: e.currentTarget.value
        } as IUser);
    };

    const setUserRole = (e: ChangeEvent<HTMLInputElement>) => {
        setuser({
            ...user,
            roles: [e.currentTarget.value]
        } as IUser);
    };

    const saveUser = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(password && password !== passwordconfirm){
            setErrormessage('Οι κωδικοί δεν ταιριάζουν');
            setTimeout(() => {
                setErrormessage('');
            }, 3000);
            return false;
        }
        if(user.displayName === ''){
            setErrormessage('Το όνομα είναι υποχρεωτικό');
            setTimeout(() => {
                setErrormessage('');
            }, 3000);
            return false;
        }
        if(user.newemail === ''){
            setErrormessage('Το email είναι υποχρεωτικό');
            setTimeout(() => {
                setErrormessage('');
            }, 3000);
            return false;
        }
        if(user.roles[0] === ''){
            setErrormessage('Ο Ρόλος είναι υποχρεωτικό');
            setTimeout(() => {
                setErrormessage('');
            }, 3000);
            return false;
        }
        if(newuser){
            authService.createUser(user.email, user.newemail as string, user.displayName, user.roles[0], password).then((result) => {
                setSuccessmessage('Ο χρήστης καταχωρήθηκε επιτυχώς');
                setTimeout(() => {
                    setErrormessage('');
                    if(user.newemail){
                        history.push('/users/' + user.newemail);
                    }
                    else{
                        history.push('/users/' + user.email);
                    }
                }, 3000);
            });
        }
        else{
            authService.saveUser(user.email, user.newemail as string, user.displayName, user.roles[0], password).then((result) => {
                setSuccessmessage('Ο χρήστης καταχωρήθηκε επιτυχώς');
                setTimeout(() => {
                    setErrormessage('');
                    if(user.newemail){
                        history.push('/users/' + user.newemail);
                    }
                    else{
                        history.push('/users/' + user.email);
                    }
                }, 3000);
            });
        }
        
    };

    return (
        <Row>
            <Col md="12">
                <Link to="/users" className="btn btn-warning float-left">Πίσω</Link>
                <br />
                <hr />
            </Col>
            <Col md="12">
                <Card>
                    <CardHeader className="bg-info">
                        <h3>Πληροφορίες Χρήστη</h3>
                    </CardHeader>
                    <Form onSubmit={saveUser}>
                        <CardBody>
                            {
                                successmessage && <Alert color="success">{successmessage}</Alert>
                            }
                            {
                                errormessage && <Alert color="danger">{errormessage}</Alert>
                            }
                            <FormGroup>
                                <Label>Όνομα</Label>
                                <Input type="text" name="name" defaultValue={user ? user.displayName : ''}
                                onChange={setUserDisplayName} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input type="email" name="email" autoComplete="off"  defaultValue={user ? user.email : ''} 
                                onChange={setUserEmail}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Ρόλος</Label>
                                <Input type="select" name="role" defaultValue={user ? user.roles[0] : ''}
                                onChange={setUserRole}>
                                    <option value="">Επιλέξτε ρόλο</option>
                                    {
                                        authStore.roles.map((r: string) => {
                                            return <option value={r} selected={user && user.roles[0] === r}>{r}</option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Νέος Κωδικός (συμπληρώστε μόνο για αλλαγή)</Label>
                                <Input type="password" name="password" defaultValue={''}  autoComplete="off"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => { setpassword(e.currentTarget.value) }} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Επανάληψη νέου κωδικού</Label>
                                <Input type="password" name="name" defaultValue={''} autoComplete="off"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {setPasswordconfirm(e.currentTarget.value) }}/>
                            </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <Button color="success">Αποθήκευση</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default observer(UserComponent);