import React, { useState, useContext, FC, useEffect, ChangeEvent } from 'react';
import { Row, Col, Button } from 'reactstrap';
import AuthStore from '../../../stores/AuthStore';
import AuthService from '../../../services/AuthService';
import IUser from '../../../entities/User';
import SearchStore from '../../../stores/SearchStore';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import axios, { AxiosResponse } from 'axios';
import Select from 'react-dropdown-select';

interface OldPropositionLine{
    program: string;
    title: string;
    size1: string;
    size2: string;
    municipality: string;
    size3: string;
    taxid: string;
    area: string;
    user: string;
    role: string;
    address: string;
    phone: string;
    fax: string;
    serial: number;
    file_serial: number;
    file: string;
}

const OldApplications : FC = () => {

    const authStore = useContext(AuthStore);
    const authService = new AuthService();
    const searchStore = useContext(SearchStore);
    const [olds, setOlds] = useState<OldPropositionLine[]>([]);
    const [programs, setPrograms] = useState<string[]>([]);
    const [applicants, setApplicants] = useState<string[]>([]);
    const [viewing, setViewing] = useState(-1);

    const [applicant, setApplicant] = useState('');
    const [program, setProgram] = useState('');

    useEffect(() => {
        axios.get('/old.json').then((response: AxiosResponse<OldPropositionLine[]>) => {
            
            let molds: OldPropositionLine[] = [];
            var apps: string[] = [];
            var progrs: string[] = [];
            response.data.forEach((item) => {
                let existing = molds.filter((a) => {
                    return a.serial === item.serial;
                });
                if(existing.length){
                    let index = molds.indexOf(existing[0]);
                    molds[index].file += "|" + item.file
                }
                else{
                    molds.push(item);
                }
            })
            setOlds(molds);
            for(let i = 0; i < response.data.length; i ++){
                if(!apps.includes(response.data[i].municipality)){
                    apps.push(response.data[i].municipality)
                }
                if(!progrs.includes(response.data[i].program)){
                    progrs.push(response.data[i].program)
                }
            }
            setApplicants(apps);
            setPrograms(progrs);
        })
    }, []);

    return (
        <Row>
            <Col md="12">
                <Row>
                    <Col md="6">
                        <h4>Αιτών</h4>
                        <Select values={[]} options={[{label: 'Όλοι', value: ''}, ...applicants.map((p: string) => ({label: p, value: p}))]}
                            searchable onChange={(values) => {
                                if(values){
                                setApplicant(values[0].value);
                                }
                            }} />
                    </Col>
                    <Col md="6">
                        <h4>Πρόγραμμα</h4>
                        <Select values={[]} options={[{label: 'Όλα τα προγράμματα', value: ''}, ...programs.map((p: string) => ({label: p, value: p}))]}
                            searchable onChange={(values) => {
                                if(values){
                                setProgram(values[0].value);
                                }
                            }} />
                    </Col>
                </Row>
            </Col>
            <Col md="12">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Πρόγραμμα</th>
                            <th>Δήμος</th>
                            <th>Αριθμός Μητρώου</th>
                            <th>Τίτλος Πρότασης</th>
                            <th>ΑΦΜ</th>
                            <th>ΔΟΥ</th>
                            <th>Δημιουργός</th>
                            <th>Ρόλος</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            olds.filter((o) => {
                                if(authStore.user?.roles[0] === 'Municipality'){
                                    if(authStore.user?.displayName !== 'ΔΗΜΟΣ ' + o.municipality){
                                        return false;
                                    }
                                    else{
                                        if(applicant && program){
                                            if(o.program === program && applicant === o.municipality){
                                                return true;
                                            }
                                            else{
                                                return false;
                                            }
                                        }
                                        else if(applicant && !program){
                                            if(applicant === o.municipality){
                                                return true;
                                            }
                                            else{
                                                return false;
                                            }
                                        }
                                        else if(!applicant && program){
                                            if(program === o.program){
                                                return true;
                                            }
                                            else{
                                                return false;
                                            }
                                        }
                                        else{
                                            return true;
                                        }
                                    }
                                }
                                else{
                                    if(applicant && program){
                                        if(o.program === program && applicant === o.municipality){
                                            return true;
                                        }
                                        else{
                                            return false;
                                        }
                                    }
                                    else if(applicant && !program){
                                        if(applicant === o.municipality){
                                            return true;
                                        }
                                        else{
                                            return false;
                                        }
                                    }
                                    else if(!applicant && program){
                                        if(program === o.program){
                                            return true;
                                        }
                                        else{
                                            return false;
                                        }
                                    }
                                    else{
                                        return true;
                                    }
                                }
                            }).map((o, i) => {
                                return <><tr key={i}>
                                    <td><Button color="info" size="sm" onClick={() => {i === viewing ? setViewing(-1) : setViewing(i)}}>{o.program}</Button></td>
                                    <td>{o.municipality}</td>
                                    <td>{o.serial}</td>
                                    <td>{o.title}</td>
                                    <td>{o.taxid}</td>
                                    <td>{o.area}</td>
                                    <td>{o.user}</td>
                                    <td>{o.role}</td>
                                </tr>
                                {
                                    viewing === i && o.file && o.file.split('|').map((fn, j) => {
                                        console.log(fn);
                                        return <tr key={j}>
                                            <td colSpan={8}>
                                            <a className="btn btn-primary btn-block" target="_blank" rel="noopener noreferrer" href={"https://oldfiles.intelliflow.gr/" + o.program + "/" + o.municipality + "/" + o.serial + "/" + fn }>{fn}</a>
                                            </td>
                                        </tr>
                                    })
                                }
                                </>
                            })
                        }
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}

export default observer(OldApplications);