import api from './BaseService';
import IUser from '../entities/User';
import { AxiosResponse } from 'axios';

class AuthService {

    login(email: string, password: string): Promise<IUser>{
        const LoginPromise = new Promise<IUser>((resolve, reject) => {
            api.post('user/login', {
                    email, password
                }).then((result: AxiosResponse<IUser>) => {
                    resolve(result.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                });
        });
        
        return LoginPromise;
    }

    getRoles(): Promise<string[]>{
        const getRolesPromise = new Promise<string[]>((resolve, reject) => {
            api.get('user/roles').then((response: AxiosResponse<string[]>) => {
                resolve(response.data);
            }).catch(e => {
                reject(e);
            });
        });
        return getRolesPromise;
    }

    getMechanics(): Promise<IUser[]>{
        const getMechanicsPromise = new Promise<IUser[]>((resolve, reject) => {
            api.get('user/users/Mechanic').then((response: AxiosResponse<IUser[]>) => {
                resolve(response.data);
            }).catch(e => {
                reject(e);
            });
        });
        return getMechanicsPromise;
    }

    getUsers(): Promise<IUser[]>{
        const getUsersPromise = new Promise<IUser[]>((resolve, reject) => {
            api.get('/user/users').then((response: AxiosResponse<IUser[]>) => {
                resolve(response.data);
            }).catch(e => {
                reject(e.message);
            });
        });
        return getUsersPromise;
    }

    saveUser(email: string, newemail: string, displayName: string, role: string, password: string): Promise<boolean>{
        const saveUserPromise = new Promise<boolean>((resolve, reject) => {
            api.put('user/saveuser', {
                displayName,
                email,
                newemail,
                role,
                password
            }).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e.message);
            })
        });
        return saveUserPromise;
    }

    createUser(email: string, newemail: string, displayName: string, role: string, password: string): Promise<boolean>{
        const saveUserPromise = new Promise<boolean>((resolve, reject) => {
            api.post('user/createuser', {
                displayName,
                newemail,
                email,
                role,
                password
            }).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e.message);
            })
        });
        return saveUserPromise;
    }

    logout(){
        api.post('auth/logout');
    }

}

export default AuthService;