import React, { FC, useContext, useEffect, useState, ChangeEvent, FormEvent, Fragment } from 'react';
import WorkflowStore from '../../../stores/WorkflowStore';
import WorkflowService from '../../../services/WorkflowService';
import AuthService from '../../../services/AuthService';
import { observer } from 'mobx-react-lite';
import { Row, Col, Form, Card, CardBody, Label, Input, FormGroup, CardHeader, CardFooter, Button } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import IWorkflow from '../../../entities/Workflow';
import IWorkflowStep from '../../../entities/WorkflowStep';
import { v4 as guid, v4 } from 'uuid';
import { ReactSortable } from 'react-sortablejs';
import "./WorkflowComponent.css";
import IWorkflowStepAction from '../../../entities/WorkflowStepAction';
import AuthStore from '../../../stores/AuthStore';
import IUser from '../../../entities/User';
import Select from 'react-dropdown-select';
import Count from '../../../entities/Count';

const WorkflowComponent: FC = () => {

    const workflowStore = useContext(WorkflowStore);
    const authStore = useContext(AuthStore);
    const authService = new AuthService();
    const workflowService = new WorkflowService();
    const [workflow, setworkflow] = useState<IWorkflow | undefined>(undefined);
    const [stepid, setstepid] = useState<string>('');
    const [newstep, setNewStep] = useState<IWorkflowStep | undefined>(undefined);
    const [newaction, setNewAction] = useState<IWorkflowStepAction | undefined>(undefined);
    const [step, setstep] = useState<IWorkflowStep | undefined>(undefined);
    const [action, setaction] = useState<IWorkflowStepAction | undefined>(undefined);
    const [count, setCount] = useState<Count | undefined>(undefined);
    const history = useHistory();
    const { id } = useParams<{id: string}>();

    const editWorkflowName = (e: ChangeEvent<HTMLInputElement>) => {
        setworkflow({
            ...workflow, 
            name: e.currentTarget.value
        } as IWorkflow)
    };
    const editWorkflowDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setworkflow({
            ...workflow, 
            description: e.currentTarget.value
        } as IWorkflow)
    };
    const editWorkflowStartingDate = (e: ChangeEvent<HTMLInputElement>) => {
        setworkflow({
            ...workflow,
            startingDate: e.currentTarget.value
        } as IWorkflow);
        console.log(workflow)
    };
    const editWorkflowStartingTime = (e: ChangeEvent<HTMLInputElement>) => {
        var time = e.currentTarget.value;
        if(workflow?.startingDate){
            if(workflow.startingDate.length === 10){
                workflow.startingDate += 'T' + time;
            }
            else{
                workflow.startingDate = workflow.startingDate.substring(0, 10) + 'T' + time;
            }
            setworkflow({
                ...workflow
            } as IWorkflow);
            console.log(workflow);
        }
    };
    const editWorkflowEndingDate = (e: ChangeEvent<HTMLInputElement>) => {
        setworkflow({
            ...workflow,
            endingDate: e.currentTarget.value
        } as IWorkflow);
        console.log(workflow);
    };
    const editWorkflowEndingTime = (e: ChangeEvent<HTMLInputElement>) => {
        var time = e.currentTarget.value;
        if(workflow?.endingDate){
            if(workflow.endingDate.length === 10){
                workflow.endingDate += 'T' + time;
            }
            else{
                workflow.endingDate = workflow.endingDate.substring(0, 10) + 'T' + time;
            }
            setworkflow({
                ...workflow
            } as IWorkflow);
            console.log(workflow);
        }
    };
    const editWorkflowDuration = (e: ChangeEvent<HTMLInputElement>) => {
        setworkflow({
            ...workflow, 
            duration: parseInt(e.currentTarget.value)
        } as IWorkflow)
    };
    const editWorkflowPriority = (e: ChangeEvent<HTMLInputElement>) => {
        setworkflow({
            ...workflow,
            priority: parseInt(e.currentTarget.value)
        } as IWorkflow)
    };

    useEffect(() => {
        if(id){
            if(workflowStore.workflows.size === 0){
                workflowService.getWorkflows().then((workflows: IWorkflow[]) => {
                    workflowStore.setWorkflows(workflows);
                    setworkflow(workflowStore.workflows.get(id));
                });
            }
            else{
                setworkflow(workflowStore.workflows.get(id));
            }
            workflowService.getCount(id).then((r: Count) => {
                setCount(r);
            })
        }
        else{
            setworkflow({
                id: '',
                name: '',
                description: '',
                duration: 0,
                priority: 1,
                beneficiaries: '',
                userEmailManages: '',
                startingDate: '',
                endingDate: '',
                steps: []
            });
        }
        if(authStore.user?.roles.includes('Administrator')){
            authService.getUsers().then((data: IUser[]) => {
                authStore.setUsers(data);
            });
            authService.getRoles().then(roles => {
              authStore.setRoles(roles);
              console.log(roles);
            });
        }
    }, [workflowStore, id]);

    const saveWorkflow = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(workflow?.id){
            workflowStore.setActiveWorkflow(id);
            workflowStore.editActiveWorkflow(workflow as IWorkflow);
            workflowService.editWorkflow(workflow as IWorkflow);
        }
        else{
            workflowService.addWorkflow(workflow as IWorkflow).then(id => {
                setworkflow({
                    ...workflow, 
                    id
                } as IWorkflow);
                history.push('/workflow/' + id);
            }).catch(e => {
                console.log(e);
            });
        }
    };

    const deleteWorkflow = () => {
        if(id){
            if(window.confirm('Are you sure you want to delete the workflow?')){
                workflowService.deleteWorkflow(id).then(() => {
                    workflowStore.deleteWorkflow(id);
                    history.push('/workflows');
                });
            }
        }
        else{
            history.push('/workflows');
        }
    };

    const changeStepName = (e: ChangeEvent<HTMLInputElement>) => {
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]?.id === stepid){
                workflow.steps[i] = {
                    ...workflow.steps[i],
                    name: e.currentTarget.value
                };
            }
        }
    };

    const changeStepDescription = (e: ChangeEvent<HTMLInputElement>) => {
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]?.id === stepid){
                workflow.steps[i] = {
                    ...workflow.steps[i],
                    description: e.currentTarget.value
                };
            }
        }
    };

    const changeStepDuration = (e: ChangeEvent<HTMLInputElement>) => {
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]?.id === stepid){
                workflow.steps[i] = {
                    ...workflow.steps[i],
                    duration: parseInt(e.currentTarget.value)
                };
            }
        }
    };

    const changeStepGetsProtocol = (e: ChangeEvent<HTMLInputElement>) => {
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]?.id === stepid){
                workflow.steps[i] = {
                    ...workflow.steps[i],
                    getsProtocol: e.currentTarget.checked
                };
            }
        }
    };

    const changeStepRole = (e: ChangeEvent<HTMLInputElement>) => {
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]?.id === stepid){
                workflow.steps[i] = {
                    ...workflow.steps[i],
                    role: e.currentTarget.value
                };
                console.log(workflow?.steps[i]);
            }
        }
    }

    const changeStepStatus = (e: ChangeEvent<HTMLInputElement>) => {
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]?.id === stepid){
                workflow.steps[i] = {
                    ...workflow.steps[i],
                    status: parseInt(e.currentTarget.value)
                };
            }
        }
    };

    const setWorkflowSteps = (steps: IWorkflowStep[]) => {
        for(let i = 0; i < steps.length; i ++){
            steps[i].status = i + 1;
        }
        if(workflow){
            setworkflow({
                ...workflow,
                steps
            });
            saveSteps();
        }
    };

    const addStep = () => {
        const newid: string = guid();
        setNewStep ({
            id: newid,
            name: '',
            description: '',
            status: 0,
            duration: 0,
            role: '',
            workflowid: id,
            getsProtocol: false,
            actions: []
        });
    };

    const changeNewStepName = (e: ChangeEvent<HTMLInputElement>) => {
        setNewStep({
            ...newstep,
            name: e.currentTarget.value
        } as IWorkflowStep);
    };

    const changeNewStepDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setNewStep({
            ...newstep,
            description: e.currentTarget.value
        } as IWorkflowStep);
    };

    const changeNewStepGetsProtocol = (e: ChangeEvent<HTMLInputElement>) => {
        setNewStep({
            ...newstep,
            getsProtocol: e.currentTarget.checked
        } as IWorkflowStep);
    };

    const changeNewStepDuration = (e: ChangeEvent<HTMLInputElement>) => {
        setNewStep({
            ...newstep,
            duration: parseInt(e.currentTarget.value)
        } as IWorkflowStep);
    };

    const changeNewStepRole = (e: ChangeEvent<HTMLInputElement>) => {
        setNewStep({
            ...newstep,
            role: e.currentTarget.value
        } as IWorkflowStep);
    };

    const changeNewStepStatus = (e: ChangeEvent<HTMLInputElement>) => {
        setNewStep({
            ...newstep,
            status: parseInt(e.currentTarget.value)
        } as IWorkflowStep);
    };

    const createStep = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        workflowStore.setActiveWorkflow(id);
        workflowStore.addWorkflowStep(newstep as IWorkflowStep);
        workflowService.addWorkflowStep(newstep as IWorkflowStep).then((r) => {
            if(workflow){
                setworkflow({
                    ...workflow,
                    steps: [
                        ...workflow.steps,
                        newstep as IWorkflowStep
                    ]
                });
            }
            setNewStep(undefined);  
        }).catch(e => {
            console.log(e);
        });
        
    };

    const saveStep = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]){
                if(workflow.steps[i].id === stepid){
                    const step = workflow.steps[i];
                    workflowStore.setActiveWorkflow(id);
                    workflowStore.editWorkflowStep(step);
                    workflowService.editWorkflowStep(step);
                }
            }
        }
    };

    const saveSteps = () => {
        workflowStore.setActiveWorkflow(id);
        for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
            if(workflow?.steps[i]){
                const step = workflow.steps[i];
                workflowStore.editWorkflowStep(step);
                workflowService.editWorkflowStep(step);
            }
        }
    }

    const selectStep = (id: string, ws: IWorkflowStep) => {
        if(stepid === id){
            setstepid('');
            setstep(undefined);
        }
        else{
            setstepid(id);
            setstep(ws);
        }
    };

    const getAvailableStatus = (): number => {
        if(workflow?.steps && workflow.steps.length > 0){
            return workflow.steps[workflow.steps.length - 1].status + 1;
        }
        else{
            return 1;
        }
    };

    const deleteStep = () => {
        if(workflow){
            workflowStore.setActiveWorkflow(id);
            workflowStore.deleteWorkflowStep(stepid);
            workflowService.deleteWorkflowStep(stepid);
            const steps: Array<IWorkflowStep> = [];
            for(let i: number = 0; i < (workflow?.steps?.length ?? 0); i ++){
                if(workflow?.steps[i].id !== stepid){
                    steps.push(workflow?.steps[i] as IWorkflowStep);
                }
            }
            setworkflow({
                ...workflow,
                steps
            });
        }
    };

    const addAction = () => {
        setNewAction({
            id: v4(),
            name: '',
            order: step?.actions ? step.actions.length + 1 : 1,
            runTime: '',
            description: '',
            target: '',
            workflowStepId: step?.id ?? '',
            type: '',
            required: true
        });
    };

    const setNewActionName = (e: ChangeEvent<HTMLInputElement>) => {
        setNewAction({
            ...newaction,
            name: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(newaction);
    };

    const setNewActionRequired = (e: ChangeEvent<HTMLInputElement>) => {
        setNewAction({
            ...newaction,
            required: e.currentTarget.checked
        } as IWorkflowStepAction);
        console.log(newaction);
    };

    const setNewActionDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setNewAction({
            ...newaction,
            description: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(newaction);
    };

    const setNewActionType = (e: ChangeEvent<HTMLInputElement>) => {
        setNewAction({
            ...newaction,
            type: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(newaction);
    };

    const setNewActionRuntime = (e: ChangeEvent<HTMLInputElement>) => {
        setNewAction({
            ...newaction,
            runTime: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(newaction);
    };

    const setNewActionTarget = (e: ChangeEvent<HTMLInputElement>) => {
        setNewAction({
            ...newaction,
            target: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(newaction);
    };

    const saveAction = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(newaction){
            console.log(newaction);
            if(newaction.name === '' || newaction.runTime === '' || newaction.type === '' || newaction.target === ''){
                console.log(newaction);
                return false;
            }
            workflowStore.setActiveWorkflow(id);
            workflowService.saveWorkflowStepAction(newaction as IWorkflowStepAction).then(r => {
                workflowStore.addWorkflowStepAction(stepid, newaction as IWorkflowStepAction);
                setNewAction(undefined);
            });
        }
    };

    const setActionName = (e: ChangeEvent<HTMLInputElement>) => {
        setaction({
            ...action,
            name: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(action);
    };

    const setActionRequired = (e: ChangeEvent<HTMLInputElement>) => {
        setaction({
            ...action,
            required: e.currentTarget.checked
        } as IWorkflowStepAction);
        console.log(action);
    };

    const setActionDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setaction({
            ...action,
            description: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(action);
    };

    const setActionType = (e: ChangeEvent<HTMLInputElement>) => {
        setaction({
            ...action,
            type: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(action);
    };

    const setActionRuntime = (e: ChangeEvent<HTMLInputElement>) => {
        setaction({
            ...action,
            runTime: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(action);
    };

    const setActionTarget = (e: ChangeEvent<HTMLInputElement>) => {
        setaction({
            ...action,
            target: e.currentTarget.value
        } as IWorkflowStepAction);
        console.log(action);
    };

    const saveExistingAction = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(action){
            console.log(action);
            if(action.name === '' || action.runTime === '' || action.type === '' || action.target === ''){
                console.log(action);
                return false;
            }
            workflowStore.setActiveWorkflow(id);
            workflowService.updateWorkflowStepAction(action as IWorkflowStepAction).then(r => {
                setaction(action);
            });
        }
    };

    const deleteAction = (actionid: string) => {
        workflowStore.setActiveWorkflow(actionid);
        workflowService.deleteAction(actionid).then(res => {
            let actions = workflowStore.deleteAction(stepid, actionid);
            setstep({
                ...step,
                actions
            } as IWorkflowStep);
            let wsteps = [];
            let initSteps = workflow?.steps ? workflow?.steps : [];
            for(let i = 0; i < initSteps.length; i ++){
                if(initSteps[i].id === stepid){
                    wsteps.push(step);
                }
                else{
                    wsteps.push(initSteps[i]);
                }
            }
            setworkflow({
                ...workflow,
                steps: wsteps
            } as IWorkflow);
        });
    };

    const setWorkflowStepActions = (actions: IWorkflowStepAction[]) => {
        console.log(actions);
        for(let i = 0; i < actions.length; i ++){
            actions[i].order = i + 1;
        }
        if(workflow){
            setstep({
                ...step,
                actions
            } as IWorkflowStep);
        }
        saveActions();
    };

    const saveActions = () => {
        if(workflow && step && step.actions){
            for(let i = 0; i < step.actions.length; i ++){
                workflowService.updateWorkflowStepAction(step.actions[i]);
            }
        }
    }

    const updateBeneficiaries = (values: {label: string, value: string}[]) => {
        let emails = [];
        for(let i = 0; i < values.length; i ++){
            emails.push(values[i].value);
        }
        setworkflow({
            ...workflow,
            beneficiaries: emails.join(',')
        } as IWorkflow);
    };
    
    const updateManager = (email: string) => {
        setworkflow({
            ...workflow,
            userEmailManages: email
        } as IWorkflow);
    };

    return (
        <Row>
            <Col md="12">
                <Button color="danger" className="float-right" onClick={deleteWorkflow}><span className="fa fa-trash"></span> Διαγραφή</Button>
                {!stepid &&
                    <Link to="/workflows" className="btn btn-warning float-right"><span className="fa fa-arrow-left"></span> Πίσω στα προγράμματα</Link>
                }
                {
                    stepid && 
                    <Button className="btn-warning float-right" onClick={() => { setstepid(''); setstep(undefined) }}><span className="fa fa-arrow-left"></span> Πίσω στα προγράμματα</Button>
                }
                <h1>Πρόγραμμα[<span className="small text-primary">{workflow?.name}</span>]</h1>
            </Col>
            {!stepid && 
                <Col md="6">
                    <Form onSubmit={saveWorkflow}>
                        <Card>
                            <CardHeader>
                                <h2>Details</h2>
                                {id && count && <p>Υποβεβλημένες: {count.submitted}, Μη υποβεβλημένες: {count.nonSubmitted}</p>}
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Label>
                                        Name
                                    </Label>
                                    <Input type="text" onChange={editWorkflowName} name="name" defaultValue={workflow?.name}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Description
                                    </Label>
                                    <Input type="textarea" onChange={editWorkflowDescription} name="description" defaultValue={workflow?.description}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Δικαιούχοι
                                    </Label>
                                    <Select multi 
                                        values={authStore.users.reduce<{label: string, value: string}[]>((benefs: {label: string, value: string}[], u: IUser) => {
                                            if(workflow?.beneficiaries && workflow?.beneficiaries.includes(u.email)){
                                                benefs.push({
                                                    label: u.displayName,
                                                    value: u.email
                                                })
                                            }
                                            return benefs;
                                        }, [])}
                                     options={[{label: 'Ολοι', value: ''}, 
                                                                ...authStore.users.sort((u1, u2) => u1.displayName > u2.displayName ? 1 : -1).map((u: IUser) => 
                                                                        ({label: u.displayName, value: u.email}))]}
                                        searchable onChange={(values) => {
                                            updateBeneficiaries(values);
                                        }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Διαχειριστής
                                    </Label>
                                    <Select values={workflow && workflow.userEmailManages && authStore.users.length > 0 ? 
                                            [{label: authStore.users.filter((u) => u.email === workflow.userEmailManages)[0].displayName, value: workflow.userEmailManages}] 
                                            : []} options={ 
                                                    authStore.users.filter(u => u.roles[0] === 'Mechanic' || u.roles[0] === 'Manager').map((u: IUser) => 
                                                            ({label: u.displayName, value: u.email}))}
                                        searchable onChange={(values) => {
                                            updateManager(values[0].value);
                                        }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Ημερομηνία Έναρξης
                                    </Label>
                                    <Input type="date" onChange={editWorkflowStartingDate} name="startingDate" 
                                    defaultValue={workflow?.startingDate ? 
                                    workflow?.startingDate.split('T')[0]: ''}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Ώρα Έναρξης
                                    </Label>
                                    <Input type="time" onChange={editWorkflowStartingTime} name="startingTime" 
                                    defaultValue={workflow?.startingDate ? 
                                        workflow?.startingDate.split('T')[1] : undefined}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Ημερομηνία Λήξης
                                    </Label>
                                    <Input type="date" onChange={editWorkflowEndingDate} name="endingDate" 
                                    defaultValue={workflow?.endingDate ? 
                                        workflow?.endingDate.split('T')[0] : undefined}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Ώρα Λήξης
                                    </Label>
                                    <Input type="time" onChange={editWorkflowEndingTime} name="endingTime" 
                                    defaultValue={workflow?.endingDate ? 
                                        workflow?.endingDate.split('T')[1] : undefined}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Duration (in days)
                                    </Label>
                                    <Input type="number" min="0" onChange={editWorkflowDuration} name="duration" defaultValue={workflow?.duration}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Priority (Bigger is lower priority)
                                    </Label>
                                    <Input type="number" min="1" onChange={editWorkflowPriority} name="priority" defaultValue={workflow?.priority}/>
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button color="primary" className="float-right"><span className="fa fa-save"></span> Save</Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            }
            <Col md="6">
                <Card>
                    <CardHeader>
                        <h2>Steps</h2>
                    </CardHeader>
                    <CardBody>
                        <Card color="light">
                            <CardBody>
                                <h3 className="text-center"><span className="fa fa-traffic-light"></span> Begin Workflow</h3>
                            </CardBody>
                        </Card>
                        <hr />
                        {
                            workflow?.steps && <ReactSortable list={workflow?.steps} setList={setWorkflowSteps} handle=".sort-handle">
                            {
                                workflow?.steps?.map((ws: IWorkflowStep) => {
                                    return (
                                        <Fragment key={ws.id}>
                                            <Card >
                                                <CardHeader  className="clickable sort-handle" onClick={() => { selectStep(ws.id, ws) }}>
                                                    <h3 className="text-center">{ ws.name }</h3>
                                                </CardHeader>
                                                {
                                                    stepid === ws.id && (
                                                        <Fragment>
                                                            
                                                            <Form onSubmit={saveStep}>
                                                                <CardBody>
                                                                    <FormGroup>
                                                                        <Label>Name</Label>
                                                                        <Input type="text" name="name" defaultValue={ws.name} onChange={changeStepName} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>Gets Protocol</Label>
                                                                        <Input type="checkbox" name="getsProtocol" defaultChecked={ws.getsProtocol} onChange={changeStepGetsProtocol} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>Description</Label>
                                                                        <Input type="textarea" name="description" defaultValue={ws.description} onChange={changeStepDescription} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>Completed by</Label>
                                                                        <Input type="select" name="role" defaultValue={ws.role} onChange={changeStepRole}>
                                                                            <option value=''>Select Role</option>
                                                                            {
                                                                                authStore.roles.map((r: string) => {
                                                                                    return <option value={r} key={r}>{r}</option>
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>Duration (in days)</Label>
                                                                        <Input type="number" name="duration" min="0" defaultValue={ws.duration} onChange={changeStepDuration} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>Status</Label>
                                                                        <Input type="number" readOnly name="status" defaultValue={ws.status} onChange={changeStepStatus} />
                                                                    </FormGroup>
                                                                </CardBody>
                                                                <CardFooter>
                                                                    <Button color="primary" className="float-right"><span className="fa fa-save"></span> Save</Button>
                                                                    <Button type="button" color="danger" onClick={deleteStep}><span className="fa fa-trash"></span> Delete Step</Button>
                                                                </CardFooter>
                                                            
                                                            </Form>
                                                        </Fragment>
                                                    )
                                                }
                                            </Card>
                                        </Fragment>
                                    )
                                })
                            }
                            </ReactSortable>
                        }
                        
                        {
                            newstep && (
                                <Fragment>
                                    <Card>
                                        <Form onSubmit={createStep}>
                                            <CardHeader color="info">
                                                <h3 className="text-center">{ newstep.name }</h3>
                                            </CardHeader>
                                            <CardBody>
                                                    <FormGroup>
                                                        <Label>Name</Label>
                                                        <Input type="text" name="name" defaultValue={newstep.name} onChange={changeNewStepName} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="checkbox" name="getsProtocol" defaultChecked={newstep.getsProtocol} onChange={changeNewStepGetsProtocol} />
                                                        <Label>Gets Protocol</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Description</Label>
                                                        <Input type="textarea" name="description" defaultValue={newstep.description} onChange={changeNewStepDescription} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Completed by</Label>
                                                        <Input type="select" name="role" defaultValue={newstep.role} onChange={changeNewStepRole}>
                                                            <option value=''>Select Role</option>
                                                            {
                                                                authStore.roles.map((r: string) => {
                                                                    return <option value={r}>{r}</option>
                                                                })
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Duration (in days)</Label>
                                                        <Input type="number" name="duration" min="0" defaultValue={newstep.duration} onChange={changeNewStepDuration} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Status</Label>
                                                        <Input type="number" readOnly name="status" defaultValue={getAvailableStatus()} onChange={changeNewStepStatus} />
                                                    </FormGroup>
                                            </CardBody>
                                            <CardFooter>
                                                <Button color="primary" className="float-right"><span className="fa fa-save"></span> Save</Button>
                                            </CardFooter>
                                        </Form>
                                    </Card>
                                    <hr />
                                </Fragment>
                            )
                        }
                        <hr />
                        <Card color="success" className="btn btn-link" onClick={addStep}>
                            <CardBody>
                                <h3 className="text-center"><span className="fa fa-plus"></span> Add Step</h3>
                            </CardBody>
                        </Card>
                        <hr />
                        <Card color="light">
                            <CardBody>
                                <h3 className="text-center"><span className="fa fa-flag-checkered"></span> End Workflow</h3>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </Col>
            {
                stepid && 
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <h2 className="text-center">Step Actions[{step?.name}]</h2>
                        </CardHeader>
                        <CardBody>
                            <h4>Αυτόματη εκτέλεση στην έναρξη του βήματος</h4>
                            {
                                step?.actions.filter((action: IWorkflowStepAction) => action.runTime === 'before')
                                .map((a: IWorkflowStepAction) => {
                                    return(
                                    <Fragment key={a.id}>
                                        <Card>
                                            <CardHeader className="bg-light" onClick={() => setaction(a)}>
                                                <h3>{a.name}</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <p>{ a.description }</p>
                                                {
                                                    a.type === 'sendEmail' && (
                                                    <h5>Send Email to {a.target}</h5>
                                                    )
                                                }
                                                {
                                                    a.type === 'createDocument' && (
                                                    <h5>Create the document {a.target}</h5>
                                                    )
                                                }
                                                {
                                                    a.type === 'completeField' && (
                                                    <h5>User has to complete the {a.target} field</h5>
                                                    )
                                                }
                                            </CardBody>
                                            <CardFooter>
                                                <Button color="danger" className="float-right" onClick={() => { deleteAction(a.id) }}>Delete</Button>
                                            </CardFooter>
                                        </Card>
                                    </Fragment>
                                    )
                                })
                            }
                            <hr />
                            <h4>Ενέργειες του χρήστη</h4>
                            <ReactSortable list={step?.actions as IWorkflowStepAction[]} setList={setWorkflowStepActions} handle={".sort-handle"}>
                            {
                                step?.actions.filter((action: IWorkflowStepAction) => action.runTime === 'during')
                                .map((a: IWorkflowStepAction) => {
                                    console.log(a.order + ' ' + a.name);
                                    return(
                                    <Fragment key={a.id}>
                                        <Card>
                                            <CardHeader className="bg-warning sort-handle" onClick={() => { setaction(a) }}>
                                                <h3>{a.name}</h3>
                                            </CardHeader>
                                            {
                                                a.id === action?.id && 
                                                <CardBody>
                                                <Form onSubmit={saveExistingAction}>
                                                    <FormGroup>
                                                        <Label>Όνομα</Label>
                                                        <Input type="text" defaultValue={a.name} name="action_name" onChange={setActionName} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Περιγραφή</Label>
                                                        <Input type="textarea" defaultValue={a.description} 
                                                            name="action_description" onChange={setActionDescription} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input type="checkbox" defaultChecked={a.required} 
                                                            name="action_required" onChange={setActionRequired} />
                                                        <Label>Υποχρεωτικό:</Label>
                                                        
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Time of Execution</Label>
                                                        <Input type="select" defaultValue={a.runTime} name="action_runtime" onChange={setActionRuntime}>
                                                            <option value="">Επιλέξτε τον χρόνο εκτέλεσης</option>
                                                            <option value="before">Αυτόματη εκτέλεση στην αρχή του βήματος</option>
                                                            <option value="during">Ενέργειες από το χρήστη</option>
                                                            <option value="after">Αυτόματη εκτέλεση στο τέλος του βήματος</option>
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Type of Action</Label>
                                                        <Input type="select" defaultValue={a.type} name="action_type" onChange={setActionType}>
                                                            <option value="">Είδος Ενέργειας</option>
                                                            {
                                                                (a.runTime === 'before' || a.runTime === 'after') &&
                                                                    <Fragment>
                                                                    <option value="sendEmail">Αποστολή Email σε χρήστη</option>
                                                                    <option value="createDocument">Δημιουργία Εγγράφου</option>
                                                                    </Fragment>
                                                            }
                                                            {
                                                                a.runTime === 'during' && <Fragment>
                                                                    <option value="completeField">Συμπλήρωση Πεδίου</option>
                                                                    <option value="uploadFile">Ανέβασμα Αρχείου</option>
                                                                    <option value="selectAnOption">Επιλογή μίας από τις επιλογές</option>
                                                                    <option value="selectMultipleOptions">Επιλογή πολλών από τις επιλογές</option>
                                                                    <option value="checkFields">Έλεγχος δεδομένων του χρήστη</option>
                                                                    <option value="assignToMechanic">Ανάθεση σε Μηχανικό</option>
                                                                    <option value="assignToMechanics">Ανάθεση σε επιπλέον μηχανικούς</option>
                                                                    <option value="mechanicAction">Ανάληψη ή Απόρριψη από Μηχανικό</option>
                                                                </Fragment>
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Πεδίο Target [συμπληρώνεται ανάλογα με τον τύπο της ενέργειας]</Label>
                                                        <Input type="textarea" defaultValue={a.target} 
                                                            name="action_target" onChange={setActionTarget} />
                                                    </FormGroup>
                                                    <Button color="success">Αποθήκευση</Button>
                                                </Form>
                                            </CardBody>
                                            }
                                            
                                            <CardFooter>
                                                <Button color="danger" className="float-right" onClick={() => { deleteAction(a.id) }}>Delete</Button>
                                            </CardFooter>
                                        </Card>
                                    </Fragment>
                                    )
                                })
                            }
                            </ReactSortable>
                            <hr />
                            <h4>Αυτόματη εκτέλεση στο τέλος του βήματος</h4>
                            {
                                step?.actions.filter((action: IWorkflowStepAction) => action.runTime === 'after')
                                .map((a: IWorkflowStepAction) => {
                                    return(
                                    <Fragment key={a.id}>
                                        <Card>
                                            <CardHeader className="bg-light">
                                                <h3>{a.name}</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <p>{ a.description }</p>
                                                {
                                                    a.type === 'sendEmail' && (
                                                    <h5>Αποστολή email στον {a.target}</h5>
                                                    )
                                                }
                                                {
                                                    a.type === 'createDocument' && (
                                                    <h5>Δημιουργία του εγγράφου {a.target}</h5>
                                                    )
                                                }
                                                {
                                                    a.type === 'completeField' && (
                                                    <h5>Ο χρήστης πρέπει να συμπληρώσει το πεδίο {a.target}</h5>
                                                    )
                                                }
                                            </CardBody>
                                            <CardFooter>
                                                <Button color="danger" className="float-right" onClick={() => { deleteAction(a.id) }}>Delete</Button>
                                            </CardFooter>
                                        </Card>
                                    </Fragment>
                                    )
                                })
                            }
                            
                            <hr />
                            {
                                newaction && (
                                    <Form onSubmit={saveAction}>
                                        <FormGroup>
                                            <Label>Όνομα</Label>
                                            <Input type="text" defaultValue={newaction.name} name="action_name" onChange={setNewActionName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Περιγραφή</Label>
                                            <Input type="textarea" defaultValue={newaction.description} 
                                                name="action_description" onChange={setNewActionDescription} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="checkbox" defaultChecked={newaction.required} 
                                                name="action_required" onChange={setNewActionRequired} />
                                            <Label>Υποχρεωτικό:</Label>
                                            
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Time of Execution</Label>
                                            <Input type="select" defaultValue={newaction.runTime} name="action_runtime" onChange={setNewActionRuntime}>
                                                <option value="">Επιλέξτε τον χρόνο εκτέλεσης</option>
                                                <option value="before">Αυτόματη εκτέλεση στην αρχή του βήματος</option>
                                                <option value="during">Ενέργειες από το χρήστη</option>
                                                <option value="after">Αυτόματη εκτέλεση στο τέλος του βήματος</option>
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Type of Action</Label>
                                            <Input type="select" defaultValue={newaction.type} name="action_type" onChange={setNewActionType}>
                                                <option value="">Είδος Ενέργειας</option>
                                                {
                                                    (newaction.runTime === 'before' || newaction.runTime === 'after') &&
                                                        <Fragment>
                                                        <option value="sendEmail">Αποστολή Email σε χρήστη</option>
                                                        <option value="createDocument">Δημιουργία Εγγράφου</option>
                                                        </Fragment>
                                                }
                                                {
                                                    newaction.runTime === 'during' && <Fragment>
                                                        <option value="completeField">Συμπλήρωση Πεδίου</option>
                                                        <option value="uploadFile">Ανέβασμα Αρχείου</option>
                                                        <option value="selectAnOption">Επιλογή μίας από τις επιλογές</option>
                                                        <option value="selectMultipleOptions">Επιλογή πολλών από τις επιλογές</option>
                                                        <option value="checkFields">Έλεγχος δεδομένων του χρήστη</option>
                                                        <option value="assignToMechanic">Ανάθεση σε Μηχανικό</option>
                                                        <option value="assignToMechanics">Ανάθεση σε επιπλέον μηχανικούς</option>
                                                        <option value="mechanicAction">Ανάληψη ή Απόρριψη από Μηχανικό</option>
                                                    </Fragment>
                                                }
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Πεδίο Target [συμπληρώνεται ανάλογα με τον τύπο της ενέργειας]</Label>
                                            <Input type="textarea" defaultValue={newaction.target} 
                                                name="action_target" onChange={setNewActionTarget} />
                                        </FormGroup>
                                        <Button color="success">Αποθήκευση</Button>
                                    </Form>
                                )
                            }
                            <Card color="success" className="btn btn-link" onClick={addAction}>
                                <CardBody>
                                    <h3 className="text-center"><span className="fa fa-plus"></span> Προσθήκη Ενέργειας</h3>
                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                </Col>
            }
        </Row>
    )
};

export default observer(WorkflowComponent);