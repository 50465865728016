import React, { useState, useContext, FC, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import AuthStore from '../../../stores/AuthStore';
import AuthService from '../../../services/AuthService';
import IUser from '../../../entities/User';
import SearchStore from '../../../stores/SearchStore';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const UsersComponent : FC = () => {

    const authStore = useContext(AuthStore);
    const authService = new AuthService();
    const searchStore = useContext(SearchStore);

    useEffect(() => {
      authService.getUsers().then((data: IUser[]) => {
          authStore.setUsers(data);
      });
      if(authStore.user?.roles.includes('Administrator')){
        authService.getRoles().then(roles => {
          authStore.setRoles(roles);
          console.log(roles);
        });
      }
    }, [authStore]);

    return (
        <Row>
            <Col md="12">
                <Link to="/users/new" className="btn btn-primary float-right">Νέος Χρήστης</Link>
                <br />
                <hr />
            </Col>
            <Col md="12">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Όνομα</th>
                            <th>E-mail</th>
                            <th>Ρόλος</th>
                            <th>Ενέργειες</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            authStore.getUsers.filter((u: IUser) => {
                                if(u.displayName.toUpperCase().includes(searchStore.search.toUpperCase()) || u.email.toUpperCase().includes(searchStore.search.toUpperCase())
                                    || u.roles[0].toUpperCase().includes(searchStore.search.toUpperCase())){
                                        return true;
                                }
                                else{
                                    return false;
                                }
                            })
                            .map((u: IUser, i: number) => {
                                return <tr key={i}>
                                    <td>{u.displayName}</td>
                                    <td>{u.email}</td>
                                    <td>{
                                        u.roles.map((r: string, i: number) => {
                                            return <span style={{background: '#ccc', padding: '3px'}}>{r}</span>
                                        })
                                        }
                                    </td>
                                    <td>
                                        <Link to={'/users/' + u.email} className="btn btn-info">Επεξεργασία</Link>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}

export default observer(UsersComponent);