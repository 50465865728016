import api from './BaseService';
import IWorkflow from '../entities/Workflow';
import Axios, { AxiosResponse } from 'axios';
import IWorkflowInstance from '../entities/WorkflowInstance';
import IWorkflowInstanceStateAction from '../entities/WorkflowInstanceStateAction';
import IWorkflowStepAction from '../entities/WorkflowStepAction';
import { rejects } from 'assert';
import IWorkflowInstanceComment from '../entities/WorkflowInstanceComment';

class MyWorkflowService {

    getWorkflows() : Promise<Array<IWorkflow>>{
        const workflowsPromise = new Promise<Array<IWorkflow>>((resolve, reject) => {
            api.get('workflowinstances/available').then((response: AxiosResponse<Array<IWorkflow>>) => {
                for(let i: number = 0; i < response.data.length; i ++){
                    for(let j: number = 0; j < (response.data[i].steps?.length ?? 0); j ++){
                        response.data[i].steps[j].workflowid = response.data[i].id;
                    }
                }
                resolve(response.data);
            }).catch(e => {
                reject(e);
            });
        });

        return workflowsPromise;
    }

    getMyWorkflows(search: string, page: number, perPage: number, orderBy: string, orderDir: string): Promise<{ instances: Array<IWorkflowInstance>, page: number, perPage: number, total: number }>{
        const getMyWorkflowsPromise = new Promise<{ instances: Array<IWorkflowInstance>, page: number, perPage: number, total: number }>((resolve, reject) => {
            api.post('workflowinstances/list', {
                search, page, perPage, orderBy, orderDir
            }).then((response: AxiosResponse<{ instances: Array<IWorkflowInstance>, page: number, perPage: number, total: number }>) => {
                for(let i = 0; i < response.data.instances.length; i ++){
                    response.data.instances[i].startedAt = new Date(response.data.instances[i].startedAt);
                }
                resolve(response.data);
            }).catch(e => {
                reject(e.message);
            });
        });
        return getMyWorkflowsPromise;
    }

    getWorkflow(id: string): Promise<IWorkflowInstance>{
        const getWorkflowPromise = new Promise<IWorkflowInstance>((resolve, reject) => {
            api.get(`workflowinstances/${id}`).then((response: AxiosResponse<IWorkflowInstance>) => {
                resolve(response.data);
            }).catch(e => {
                reject(e.message);
            })
        });
        return getWorkflowPromise;
    }

    startWorkflow(workflowinstance: {id: string, workflowId: string, startedAt: Date}): Promise<boolean>{
        const startWorkflowPromise = new Promise<boolean>((resolve, reject) => {
            api.post('workflowinstances', workflowinstance).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e.message);
            });
        });
        return startWorkflowPromise;
    }

    deleteWorkflow(id: string): Promise<boolean>{
        const deleteWorkflowPromise = new Promise<boolean>((resolve, reject) => {
            api.delete('workflowinstances/' + id).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                console.log(e.message);
                rejects(e.message);
            })
        });
        return deleteWorkflowPromise;
    }

    nextStep(instanceId: string, status: number | undefined): Promise<boolean>{
        const nextStepPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflowinstances/nextstep', {instanceId, status}).then((response: AxiosResponse<{}>) => {
                resolve(true)
            }).catch(e => {
                reject(e.message);
            })
        });
        return nextStepPromise;
    }

    uploadFile(file: File): Promise<string>{
        const fileDataPromise = new Promise<string>((resolve, reject) => {
            const formData = new FormData();
            formData.append('files', file);
            Axios.post('https://filemanager.intelliflow.gr', formData).then((response: AxiosResponse<any>) => {
                resolve(JSON.stringify(response.data));
            }).catch(e => {
                reject(e);
            });
        });
        return fileDataPromise;
    }

    createAction(a: IWorkflowInstanceStateAction): Promise<boolean>{
        const saveActionPromise = new Promise<boolean>((resolve, reject) => {
            api.post('workflowinstances/saveaction', a).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e.message);
            });
        });
        return saveActionPromise;
    }

    editAction(a: IWorkflowInstanceStateAction): Promise<boolean>{
        const saveActionPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflowinstances/saveaction', a).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e.message);
            })
        });
        return saveActionPromise;
    }

    rejectInstance(id: string): Promise<boolean>{
        const rejectPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflowinstances/reject', {id}).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            })
        });
        return rejectPromise;
    }

    sendBackInstance(id: string, status: number, comment?: string): Promise<boolean>{
        const rejectPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflowinstances/sendback', {id, status, comment}).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            })
        });
        return rejectPromise;
    }

    approveSendback(id: string){
        const approveSBPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflowinstances/approvesendback', {id}).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                console.log(e.message);
                resolve(false);
            })
        });
        return approveSBPromise;
    }

    declineSendback(id: string){
        const declineSBPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflowinstances/declinesendback', {id}).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                console.log(e.message);
                resolve(false);
            })
        });
        return declineSBPromise;
    }

    saveComment(comment: IWorkflowInstanceComment){
        const saveInstanceCommentPromise = new Promise((resolve, reject) => {
            api.post('workflowinstances/createcomment', comment).then((res: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                console.log(e.message);
                resolve(false);
            })
        });
        return saveInstanceCommentPromise;
    }
    
    updateAssignee(id: string, email: string) {
        const updateAssigneePromise = new Promise((resolve, reject) => {
            api.post('workflowinstances/changeassignee', { id, email }).then((r: AxiosResponse<{}>) => resolve(true))
                .catch(e => console.log(e));
        });
        return updateAssigneePromise;
    }

}

export default MyWorkflowService;