import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const FooterComponent: FC = () => {
    return (
        <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © 2020{" "}
              <a
                className="font-weight-bold ml-1"
                href="http://www.ypeka.gr/el-gr/"
                rel="noopener noreferrer"
                target="_blank"
              >
                
              </a>
            </div>
          </Col>

          <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <NavLink
                  href="https://www.creative-tim.com?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  
                </NavLink>
              </NavItem>

            </Nav>
          </Col>
        </Row>
      </footer>
    )
}

export default observer(FooterComponent);