import React, { ChangeEvent, FC, Fragment, useContext, useEffect, useState } from 'react';
import MyWorkflowStore from '../../../stores/MyWorkflowStore';
import MyWorkflowService from '../../../services/MyWorkflowService';
import { observer } from 'mobx-react-lite';
import IWorkflow from '../../../entities/Workflow';
import IWorkflowInstance from '../../../entities/WorkflowInstance';
import { Row, Col, Button, Card, Table, Input, FormGroup, Label, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import './MyWorkflowsComponent.css';
import AuthStore from '../../../stores/AuthStore';
import AuthService from '../../../services/AuthService';
import IWorkflowInstanceStateAction from '../../../entities/WorkflowInstanceStateAction';
import IWorkflowStep from '../../../entities/WorkflowStep';
import SearchWorkflowsStore from '../../../stores/SearchWorkflowsStore';
import WorkflowStore from '../../../stores/WorkflowStore';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios, { AxiosResponse } from 'axios';
import IUser from '../../../entities/User';

const MyWorkflowsComponent : FC = () => {

    const myworkflowsStore = useContext(MyWorkflowStore);
    const myworkflowsService = new MyWorkflowService();
    const authStore = useContext(AuthStore);
    const searchStore = useContext(SearchWorkflowsStore);
    const [myWorkflows, setMyWorkflows] = useState<Array<IWorkflowInstance>>([]);
    const [workflows, setWorkflows] = useState<string[]>([]);
    const [workflowsFilter, setWorkflowsFilter] = useState<string>('');
    const [pages, setPages] = useState<number[]>([]);
    const [curPage, setCurPage] = useState<number>(1);
    const [availableUsers, setAvailableUsers] = useState<IUser[]>([]);
    const [editingAssignee, setEditingAssignee] = useState<string>('');
    const [newAssignee, setNewAssignee] = useState('');
    const authService = new AuthService();

    useEffect(() => {
        myworkflowsService.getWorkflows().then((result: Array<IWorkflow>) => {
            myworkflowsStore.setWorkflows(result);
        });
        search();
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        authService.getMechanics().then((res: IUser[]) => {
            setAvailableUsers(res);
        })
    };

    const updateAssignee = (id: string, email: string) => {
        myworkflowsService.updateAssignee(id, email).then(r => search());
    }

    const search = () => {
        console.log(searchStore.perPage);
        myworkflowsService.getMyWorkflows(searchStore.search, searchStore.page,
            searchStore.perPage, searchStore.orderBy, searchStore.orderDir)
            .then((result: {instances: Array<IWorkflowInstance>, perPage: number, page: number, total: number}) => {
                let wf = workflows;
                result.instances.map(w => {
                    if(!wf.includes(w.workflow.name))
                    wf.push(w.workflow.name);
                });
                setWorkflows(wf);
                setMyWorkflows(result.instances);
                searchStore.setPage(result.page);
                setCurPage(result.page);
                console.log(result.page);
                let numPages = (result.total/result.perPage) + 1
                let myPages: number[] = [];
                for (let i = 1; i < numPages; i++){
                    myPages.push(i);
                }
                setPages(myPages);
            });
        setWorkflows([]);
    }

    const deleteWorkflow = (id: string) => {
        if(window.confirm("Θέλετε σίγουρα να διαγράψετε την πρόταση;")){
            myworkflowsService.deleteWorkflow(id).then((r: boolean) => {
                search();
            });
        }
    };

    const setTheFilterWorkflow = (e: ChangeEvent<HTMLInputElement>) => {
        searchStore.setSearchWorkflow(e.currentTarget.value);
    };

    const printTechSpecs = (wi: IWorkflowInstance) => {
        axios.post('https://filemanager.intelliflow.gr/createtecdoc', wi).then((response: AxiosResponse<{path: string}>) => {
            window.open('https://filemanager.intelliflow.gr/' + response.data.path);
        }).catch(e => {
            console.log(e.message);
        })
    }

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    {
                        (authStore.user?.roles.includes('Municipality') || authStore.user?.roles.includes('Administrator')) && 
                        <Link className="btn btn-primary float-right"  to="/myworkflows/new">Δημιουργία νέας Πρότασης</Link>
                    }
                    <br />
                    <br />
                    <br />
                </Col>
            </Row>
            <Row>
                
                <Col md="9">
                    <FormGroup>
                        <Label>Αναζήτηση</Label>
                        <Input type="text" defaultValue={searchStore.search} name="search" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            searchStore.setSearch(e.currentTarget.value);
                            searchStore.setPage(1);
                            search();
                        }} />
                    </FormGroup>

                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label>Προτάσεις Ανά Σελίδα:</Label>
                        <Input type="select" defaultValue={searchStore.perPage} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            searchStore.setPerPage(parseInt(e.currentTarget.value));
                            searchStore.setPage(1);
                            search();
                        }}>
                            <option>10</option>
                            <option>20</option>
                            <option>40</option>
                            <option>80</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <ButtonGroup>
                        {
                            pages.map(p => {
                                if (p === 1 || p === pages.length || (p < curPage + 3 && p > curPage - 3)) {
                                    return <Button className={ p === curPage ? 'active' : '' } key={p} onClick={() => { searchStore.setPage(p); search(); }}>{p}</Button>
                                }
                            })
                        }
                    </ButtonGroup>
                </Col>
                <Col md="12">
                    <ReactHTMLTableToExcel 
                        id="my-export-button"
                        className="btn btn-success"
                        table="proposals-table"
                        filename="Προτάσεις"
                        sheet="Προτάσεις"
                        buttonText="Εξαγωγή σε Excel"
                    />
                    <Table className="table-striped" style={{width: '100%!important', maxWidth: '100%!important'}} id="proposals-table">
                        <thead>
                            <tr className="clickableCells">
                                <th style={{maxWidth: '400px'}} onClick={() => {
                                    if(searchStore.orderBy === 'serialNumber'){
                                        if(searchStore.orderDir === 'asc'){
                                            searchStore.setOrderDir('desc');
                                        }
                                        else{
                                            searchStore.setOrderDir('asc');
                                        }
                                    }
                                    else{
                                        searchStore.setOrderBy('serialNumber');
                                        searchStore.setOrderDir('asc');
                                    }
                                    searchStore.setPage(1);
                                    search();
                                }}>Α/Α</th>
                                <th style={{maxWidth: '400px'}} onClick={() => {
                                    if(searchStore.orderBy === 'protocolNumber'){
                                        if(searchStore.orderDir === 'asc'){
                                            searchStore.setOrderDir('desc');
                                        }
                                        else{
                                            searchStore.setOrderDir('asc');
                                        }
                                    }
                                    else{
                                        searchStore.setOrderBy('protocolNumber');
                                        searchStore.setOrderDir('asc');
                                    }
                                    searchStore.setPage(1);
                                    search();
                                }}>Αριθμός Πρωτοκόλλου</th>
                                <th style={{maxWidth: '400px'}} onClick={() => {
                                    if(searchStore.orderBy === 'submittedAt'){
                                        if(searchStore.orderDir === 'asc'){
                                            searchStore.setOrderDir('desc');
                                        }
                                        else{
                                            searchStore.setOrderDir('asc');
                                        }
                                    }
                                    else{
                                        searchStore.setOrderBy('submittedAt');
                                        searchStore.setOrderDir('asc');
                                    }
                                    searchStore.setPage(1);
                                    search();
                                }}>Ημερομηνία Υποβολής</th>
                                <th onClick={() => {
                                    if(searchStore.orderBy === 'title'){
                                        if(searchStore.orderDir === 'asc'){
                                            searchStore.setOrderDir('desc');
                                        }
                                        else{
                                            searchStore.setOrderDir('asc');
                                        }
                                    }
                                    else{
                                        searchStore.setOrderBy('title');
                                        searchStore.setOrderDir('asc');
                                    }
                                    searchStore.setPage(1);
                                    search();
                                }}>Πρόταση</th>
                                <th style={{maxWidth: '400px'}} onClick={() => {
                                    if(searchStore.orderBy === 'workflow'){
                                        if(searchStore.orderDir === 'asc'){
                                            searchStore.setOrderDir('desc');
                                        }
                                        else{
                                            searchStore.setOrderDir('asc');
                                        }
                                    }
                                    else{
                                        searchStore.setOrderBy('workflow');
                                        searchStore.setOrderDir('asc');
                                    }
                                    searchStore.setPage(1);
                                    search();
                                }}>Πρόγραμμα</th>
                                <th style={{maxWidth: '10%'}} onClick={() => {
                                    if(searchStore.orderBy === 'status'){
                                        if(searchStore.orderDir === 'asc'){
                                            searchStore.setOrderDir('desc');
                                        }
                                        else{
                                            searchStore.setOrderDir('asc');
                                        }
                                    }
                                    else{
                                        searchStore.setOrderBy('status');
                                        searchStore.setOrderDir('asc');
                                    }
                                    searchStore.setPage(1);
                                    search();
                                }}>Κατάσταση</th>
                                {
                                    !authStore.user?.roles.includes('Municipality') &&
                                        <th style={{maxWidth: '15%'}} onClick={() => {
                                            if(searchStore.orderBy === 'municipality'){
                                                if(searchStore.orderDir === 'asc'){
                                                    searchStore.setOrderDir('desc');
                                                }
                                                else{
                                                    searchStore.setOrderDir('asc');
                                                }
                                            }
                                            else{
                                                searchStore.setOrderBy('municipality');
                                                searchStore.setOrderDir('asc');
                                            }
                                            searchStore.setPage(1);
                                            search();
                                        }}>Δήμος</th>
                                }
                                <th style={{maxWidth: '15%'}} onClick={() => {
                                    if(searchStore.orderBy === 'mechanic'){
                                        if(searchStore.orderDir === 'asc'){
                                            searchStore.setOrderDir('desc');
                                        }
                                        else{
                                            searchStore.setOrderDir('asc');
                                        }
                                    }
                                    else{
                                        searchStore.setOrderBy('mechanic');
                                        searchStore.setOrderDir('asc');
                                    }
                                    searchStore.setPage(1);
                                    search();
                                }}>Ανατέθηκε σε</th>
                                
                                <th style={{maxWidth: '10%'}}>Ενέργειες</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myWorkflows
                                .map((wi: IWorkflowInstance) => {
                                    return (
                                        <tr key={wi.id} className={wi.paused ? 'bg-danger' : (wi.completed ? 'bg-success' : (wi.needsManagerApproval ? 'bg-warning' : ''))}>
                                            <td>{ wi.serialNumber }/{wi.startedAt.getFullYear()}</td>
                                            <td>{wi.protocolNumber}
                                            {wi.secondProtocolNumber && <>
                                                <br />
                                                Αριθμός Πρωτοκόλλου Συμπληρωματικών Εγγράφων: {wi.secondProtocolNumber}
                                            </>}</td>
                                            <td>{ wi.submittedAt.toString().startsWith("0001") ? '' : wi.submittedAt }</td>
                                            <td>{ wi.actions.length > 0 ? wi.actions.filter((a: IWorkflowInstanceStateAction) => a.status === 1  && a.action?.target === 'Τίτλος Πρότασης')[0]?.data : ''}</td>
                                            <td style={{maxWidth: '400px', wordWrap: 'break-word', whiteSpace: 'normal'}}>{wi.workflow?.name}</td>
                                            
                                            <td>{wi.workflow.steps.filter((s: IWorkflowStep) => s.status === wi.status)[0]?.name}</td>
                                            {
                                                !authStore.user?.roles.includes('Municipality') &&
                                                    <td>{wi.user.displayName}</td> 
                                            }
                                            {
                                                editingAssignee !== wi.id && <td onDoubleClick={
                                                    () => ["Administrator", "Manager"].includes(authStore.user?.roles[0] ?
                                                        authStore.user?.roles[0] :
                                                        '') && setEditingAssignee(wi.id)
                                                }>{wi.userAssigned && wi.isAssigned ? wi.userAssigned.displayName : ''}</td>
                                            }
                                            {
                                                editingAssignee === wi.id && <td>
                                                    <Input type="select" defaultValue={wi.userAssigned.email} onChange={(e) => {
                                                        setNewAssignee(e.currentTarget.value);
                                                    }}>
                                                        <option value="">Επιλέξτε Χρήστη</option>
                                                        {
                                                            availableUsers.map(u => {
                                                                return <option value={u.email}>{ u.displayName }</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <Button size="sm" color="danger" onClick={() => updateAssignee(wi.id, newAssignee)}>Aποθήκευση</Button>
                                                </td>
                                            }
                                            
                                            <td>
                                                { (!wi.needsManagerApproval || authStore.user?.roles.includes('Manager') || authStore.user?.roles.includes('Administrator')) &&
                                                    <Link className="btn btn-info" to={"/myworkflows/" + wi.id}>Επεξεργασία</Link>
                                                }
                                                {
                                                    (wi.user.email === authStore?.user?.email && !wi.protocolNumber) && 
                                                    <Button color="danger" onClick={() => {
                                                        deleteWorkflow(wi.id);
                                                    }}>Διαγραφή</Button>
                                                }
                                                <Button color="primary" onClick={() => {
                                                    printTechSpecs(wi);
                                                }}>Τεχνικό Δελτίο</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
                <Col md="12">
                    <ButtonGroup>
                        {
                            pages.map(p => {
                                if (p === 1 || p === pages.length || (p < curPage + 3 && p > curPage - 3)) {
                                    return <Button className={ p === curPage ? 'active' : '' } key={p} onClick={() => { searchStore.setPage(p); search(); }}>{p}</Button>
                                }
                            })
                        }
                    </ButtonGroup>
                </Col>
            </Row>
        </Fragment>
    )
};

export default observer(MyWorkflowsComponent);