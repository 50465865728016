import React, { FC, useContext, Fragment } from 'react';
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col
  } from "reactstrap";
import { Link, NavLink as NavLinkRRD } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AuthStore from '../../../stores/AuthStore';
import './SidebarComponent.css';

interface IProps{

};

const SidebarComponent: FC = (props: IProps) => {

  const authStore = useContext(AuthStore);

  return (
      <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
          <NavbarBrand className="pt-0">
            <img
              alt={'logo'} style={{width:'120px', maxHeight: '120px!important', height: '120px'}}
              className=""
              src={'/images/prasinotameio-logo.png'}
            />
          </NavbarBrand>
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={'/logo192.png'}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" >
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" >
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" >
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" >
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={false}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
                <Col className="collapse-brand" xs="6">
                  
                    <Link to={'/'}>
                      <img alt={'logo'} src={'/logo192.png'} />
                    </Link>
                </Col>
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => {
                      console.log('toggle clicked')
                  }}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>
            
            <NavItem>
              <NavLink
                to="/myworkflows"
                tag={NavLinkRRD}
                onClick={() => { console.log('going to workflows') }}
                activeClassName="active"
              >
                <i className="fa fa-cogs text-primary" />
                Προτάσεις
              </NavLink>
              <NavLink
                to="/oldpropositions"
                tag={NavLinkRRD}
                onClick={() => { console.log('going to workflows') }}
                activeClassName="active"
              >
                <i className="fa fa-cogs text-primary" />
                Προτάσεις παλαιού συστήματος
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                to="/mydocuments"
                tag={NavLinkRRD}
                onClick={() => { console.log('going to workflows') }}
                activeClassName="active"
              >
                <i className="fa fa-file-alt text-info" />
                Τα έγγραφά μου
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/mynotes"
                tag={NavLinkRRD}
                onClick={() => { console.log('going to workflows') }}
                activeClassName="active"
              >
                <i className="fa fa-sticky-note text-success" />
                Οι σημειώσεις μου
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/mycalendar"
                tag={NavLinkRRD}
                onClick={() => { console.log('going to workflows') }}
                activeClassName="active"
              >
                <i className="fa fa-calendar text-danger" />
                Το ημερολόγιό μου
              </NavLink> 
            </NavItem>*/}
            {authStore.user?.roles.includes("Administrator") && 
              <Fragment>
                <NavItem>
                  <hr style={{ height: '10px', marginTop: '3px', paddingTop: '3px', marginBottom: '0px', paddingBottom: '0px'}}/>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/workflows"
                    tag={NavLinkRRD}
                    onClick={() => { console.log('going to workflows') }}
                    activeClassName="active"
                  >
                    <i className="fa fa-clipboard-list text-primary" />
                    Προγράμματα
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/documents"
                    tag={NavLinkRRD}
                    onClick={() => { console.log('going to workflows') }}
                    activeClassName="active"
                  >
                    <i className="fa fa-file-alt text-info" />
                    Έγγραφα
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/users"
                    tag={NavLinkRRD}
                    onClick={() => { console.log('going to workflows') }}
                    activeClassName="active"
                  >
                    <i className="fa fa-users text-warning" />
                    Χρήστες
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/logs"
                    tag={NavLinkRRD}
                    onClick={() => { console.log('going to workflows') }}
                    activeClassName="active"
                  >
                    <i className="fa fa-file-code text-black" />
                    Logs
                  </NavLink>
                </NavItem>
              </Fragment>
            }
                
          </Nav>
          {/* Divider */}
          <hr className="my-3" />
          <img src="/images/min-logo.jpg" width="100%" alt="tt" />
          {/* Heading */}
          
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default observer(SidebarComponent);