import { observable, action, computed } from 'mobx';
import { createContext } from 'react';
import IWorkflow from '../entities/Workflow';
import IWorkflowStep from '../entities/WorkflowStep';
import IWorkflowInstanceState from '../entities/WorkflowInstanceState';
import IWorkflowInstance from '../entities/WorkflowInstance';

class MyWorkflowStore{
    
    @observable workflows: Map<string, IWorkflow>  = new Map<string, IWorkflow>();
    @observable myWorkflows: Map<string, IWorkflowInstance> = new Map<string, IWorkflowInstance>();

    @observable currentWorkflow: IWorkflowInstance | undefined = undefined;
    @observable currentStep: IWorkflowStep | undefined = undefined;
    @observable currentState: IWorkflowInstanceState | undefined = undefined;
    @observable myFiles: Array<any> = [];
    
    @action setWorkflows = (workflows: IWorkflow[]) => {
        for(let i = 0; i < workflows.length; i ++){
            this.workflows.set(workflows[i].id, workflows[i]);
        }
    };

    @action setMyWorkflows = (myworkflows: IWorkflowInstance[]) => {
        for(let i = 0; i < myworkflows.length; i ++){
            this.myWorkflows.set(myworkflows[i].id, myworkflows[i]);
        }
    };

    @action setCurrentWorkflow = (id: string) => {
        this.currentWorkflow = this.myWorkflows.get(id);
        this.setCurrentStep();
    };

    @action deleteWorkflow = (id: string) => {
        this.myWorkflows.delete(id);
    };

    @action setCurrentStep = () => {
        if(this.currentWorkflow){
            const status = this.currentWorkflow.status;
            for(let i = 0; i < this.currentWorkflow.workflow.steps.length; i ++){
                if(this.currentWorkflow.workflow.steps[i].status === status){
                    this.currentStep = this.currentWorkflow.workflow.steps[i];
                }
            }
        }
    };

    @action getAllFiles = () => {
        this.myWorkflows.forEach((wi: IWorkflowInstance, id: string) => {
            for(let i = 0; i < wi.actions.length; i ++){
                let action = wi.actions[i];
                if(action.action?.type === 'uploadFile'){
                    this.myFiles.push(action.data);
                }
            }
        });
    };

    @action updateCurrentWorkflow = (w : IWorkflowInstance) => {
        this.currentWorkflow = w;
    }

}

export default createContext(new MyWorkflowStore());