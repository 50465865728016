import { observable, action, computed } from 'mobx';
import { createContext } from 'react';
import IWorkflow from '../entities/Workflow';
import IWorkflowStep from '../entities/WorkflowStep';
import IWorkflowStepAction from '../entities/WorkflowStepAction';

class WorkflowStore{
    
    @observable workflows: Map<string, IWorkflow>  = new Map<string, IWorkflow>();
    @observable activeWorkflow: IWorkflow | undefined;
    @computed get workflowSteps() {
        if(!this.activeWorkflow){
            return [];
        }
        else{
            return this.activeWorkflow.steps.sort((a: IWorkflowStep, b: IWorkflowStep) => a.status - b.status);
        }
    }

    @action setWorkflows = (workflows: Array<IWorkflow>) => {
        for(let i: number = 0; i < workflows.length; i ++){
            this.workflows.set(workflows[i].id, workflows[i]);
        }
    };

    @action addWorkflow = (workflow: IWorkflow) => {
        if(this.workflows === undefined){
            this.workflows = new Map<string, IWorkflow>();
        }
        this.workflows.set(workflow.id, workflow);
    };

    @action deleteWorkflow = (id: string) => {
        if(this.workflows?.has(id)){
            this.workflows?.delete(id);
        }
    };

    @action setActiveWorkflow = (id: string) => {
        if(this.workflows?.has(id)){
            this.activeWorkflow = this.workflows.get(id);
        }
    }

    @action editActiveWorkflow = (workflow: IWorkflow) => {
        if(this.activeWorkflow){
            this.workflows?.set(workflow.id, workflow);
        }
    }

    @action addWorkflowStep = (workflowStep: IWorkflowStep) => {
        if(this.activeWorkflow){
            if(!this.activeWorkflow.steps){
                this.activeWorkflow.steps = new Array<IWorkflowStep>();
            }
            this.activeWorkflow.steps.push(workflowStep);
        }
    }

    @action editWorkflowStep = (workflowStep: IWorkflowStep) => {
        if(this.activeWorkflow){
            for(let i: number = 0; i < this.activeWorkflow.steps.length; i ++){
                if(this.activeWorkflow.steps[i].id === workflowStep.id){
                    this.activeWorkflow.steps[i] = workflowStep;
                }
            }
        }
    }

    @action deleteWorkflowStep = (id: string) => {
        const steps: Array<IWorkflowStep> = [];
        if(this.activeWorkflow){
            for(let i: number = 0; i < (this.activeWorkflow?.steps?.length ?? 0); i ++){
                if(this.activeWorkflow?.steps[i].id !== id){
                    steps.push(this.activeWorkflow.steps[i] as IWorkflowStep);
                }
            }
            this.activeWorkflow = {
                ...this.activeWorkflow,
                steps
            };
        }

    }

    @action addWorkflowStepAction(stepid: string, action: IWorkflowStepAction){
        if(this.activeWorkflow){
            var steps: IWorkflowStep[] = [];
            for(let i = 0; i < (this.activeWorkflow?.steps?.length ?? 0); i ++){
                if(this.activeWorkflow?.steps[i].id !== stepid){
                    steps.push(this.activeWorkflow?.steps[i]);
                }
                else{
                    this.activeWorkflow?.steps[i].actions.push(action);
                    steps.push(this.activeWorkflow?.steps[i]);
                }
            }
            this.activeWorkflow = {
                ...this.activeWorkflow,
                steps
            };
        }
    }

    @action deleteAction(stepid: string, id: string): IWorkflowStepAction[]{
        let actions = [];
        if(this.activeWorkflow){
            let steps = [];
            for(let i = 0; i < this.activeWorkflow.steps.length; i ++){
                if(this.activeWorkflow.steps[i].id === stepid){
                    let step = this.activeWorkflow.steps[i];
                    for(let j = 0; j < step.actions.length; j ++){
                        if(step.actions[j].id !== id){
                            actions.push(step.actions[j]);
                        }
                    }
                    step = {
                        ...step, 
                        actions
                    };
                    steps.push(step);
                }
                else steps.push(this.activeWorkflow.steps[i]);
            }
            this.editActiveWorkflow({
                ...this.activeWorkflow,
                steps
            });
        }
        return actions;
    }

}

export default createContext(new WorkflowStore());