import { observable, action, computed } from 'mobx';
import { createContext } from 'react';
import IUser from '../entities/User';

class AuthStore{

    @observable user: IUser | undefined; 
    @observable roles: string[] = [];
    @observable users: IUser[] = [];
    @observable sortby: string = 'displayName';
    @observable sortasc: boolean = true;

    @computed get getUsers() {
        return this.users.sort((a: IUser, b: IUser) => {
            if(this.sortby === 'displayName'){
                return a.displayName > b.displayName ? (this.sortasc ? 1 : -1) : (this.sortasc ? -1 : 1);
            }
            else if(this.sortby === 'email'){
                return a.email > b.email ? (this.sortasc ? 1 : -1) : (this.sortasc ? -1 : 1);
            }
            else{
                return a.displayName > b.displayName ? (this.sortasc ? 1 : -1) : (this.sortasc ? -1 : 1);
            }
        });
    };

    @action setSortBy = (sortby: string) => {
        if(sortby === this.sortby){
            this.sortasc = !this.sortasc;
        }
        else{
            this.sortasc = true;
        }
        this.sortby = sortby;
    };

    @action initFromSession = () => {
        const inSession: string = localStorage.getItem('iflow_user') ?? '';
        if(inSession){
            this.user = JSON.parse(inSession);
        }
        else{
            this.user = undefined;
        }
    };

    @action setUser = (user: IUser) => {
        this.user = user;
        console.log('done 1');
        localStorage.setItem('iflow_user', JSON.stringify(user));
        console.log('done 2');
        localStorage.setItem('iflow_auth_token', user.token);
        console.log('done 3');
    };

    @action logout = () => {
        this.user = undefined;
        this.roles = [];
        localStorage.setItem('iflow_user', '');
        localStorage.setItem('iflow_auth_token', '');
    };

    @action updateDetails = (user: IUser) => {
        this.user = user;
        localStorage.setItem('iflow_user', JSON.stringify(user));
        localStorage.setItem('iflow_auth_token', user.token);
    }

    @action setRoles = (roles: string[]) => {
        this.roles = roles;
    };

    @action setUsers = (users: IUser[]) => {
        this.users = users;
    };

}

export default createContext(new AuthStore());