import React, { FC, useContext, Fragment, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/css/argon-dashboard-react.css';
import './App.css';
import { observer } from 'mobx-react-lite';
import AuthStore from './stores/AuthStore';
import LoginComponent from './Components/LoginComponent/LoginComponent';
import AdminDashboardComponent from './Components/AdminDashboardComponent/AdminDashboardComponent';
import AuthService from './services/AuthService';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const App : FC = () => {

  const authStore = useContext(AuthStore);
  const authService = new AuthService();

  authStore.initFromSession();

  useEffect(() => {
    if(authStore.user?.roles.includes('Administrator')){
      authService.getRoles().then(roles => {
        authStore.setRoles(roles);
        console.log(roles);
      });
    }
  }, [authStore])

  return (
    <Fragment>
      
      <Router >
        <Switch>
          <Route path="/login" component={LoginComponent}/>
          <Route path="/" component={AdminDashboardComponent}/>
        </Switch>
      </Router>
    </Fragment>
  );
}

export default observer(App);
