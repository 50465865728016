import { observable, action } from 'mobx';
import { createContext } from 'react';

class SearchStore{

    @observable search: string = ''; 

    @action setSearch(s: string){
        this.search = s;
    }

}

export default createContext(new SearchStore());