import api from './BaseService';
import IWorkflow from '../entities/Workflow';
import { AxiosResponse } from 'axios';
import { v4 as guid } from 'uuid';
import IWorkflowStep from '../entities/WorkflowStep';
import IWorkflowStepAction from '../entities/WorkflowStepAction';
import Count from '../entities/Count';

class WorkflowService {

    getWorkflows() : Promise<Array<IWorkflow>>{
        const workflowsPromise = new Promise<Array<IWorkflow>>((resolve, reject) => {
            api.get('workflows').then((response: AxiosResponse<Array<IWorkflow>>) => {
                for(let i: number = 0; i < response.data.length; i ++){
                    for(let j: number = 0; j < (response.data[i].steps?.length ?? 0); j ++){
                        response.data[i].steps[j].workflowid = response.data[i].id;
                    }
                }
                resolve(response.data);
            }).catch(e => {
                reject(e);
            });
        });

        return workflowsPromise;
    }

    addWorkflow(workflow: IWorkflow) : Promise<string>{
        const addWorkflowPromise = new Promise<string>((resolve, reject) => {
            workflow.id = guid();
            api.post('workflows', workflow).then((response: AxiosResponse<{}>) => {
                resolve(workflow.id);
            }).catch(e => {
                reject(e);
            });
        });
        return addWorkflowPromise;
    }

    editWorkflow(workflow: IWorkflow) : Promise<boolean>{
        const editWorkflowPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflows/' + workflow.id, workflow).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(false);
            });
        });
        return editWorkflowPromise;
    }

    getCount(id: string) : Promise<Count>{
        const getCountPromise = new Promise<Count>((resolve, reject) => {
            api.get('workflowinstances/count/' + id).then((response: AxiosResponse<Count>) => {
                resolve(response.data);
            }).catch(e => {
                reject(false);
            });
        });
        return getCountPromise;
    }

    deleteWorkflow(id: string): Promise<boolean>{
        const deleteWorkflowPromise = new Promise<boolean>((resolve, reject) => {
            api.delete('workflows/' + id).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            });
        });
        return deleteWorkflowPromise;
    }

    addWorkflowStep(ws: IWorkflowStep): Promise<boolean>{
        const addWSPromise = new Promise<boolean>((resolve, reject) => {
            api.post('workflows/step', ws).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            });
        });
        return addWSPromise;
    }

    editWorkflowStep(ws: IWorkflowStep): Promise<boolean>{
        const editWSPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflows/step/' + ws.id, ws).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            });
        });
        return editWSPromise;
    }

    deleteWorkflowStep(id: string): Promise<boolean>{
        const deleteWSPromise = new Promise<boolean>((resolve, reject) => {
            api.delete('workflows/step/' + id).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            });
        });
        return deleteWSPromise;
    }

    saveWorkflowStepAction(action: IWorkflowStepAction): Promise<boolean>{
        const saveActionPromise = new Promise<boolean>((resolve, reject) => {
            api.post('workflows/step/action', action).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            })
        });
        return saveActionPromise;
    }

    updateWorkflowStepAction(action: IWorkflowStepAction): Promise<boolean>{
        const updateActionPromise = new Promise<boolean>((resolve, reject) => {
            api.put('workflows/step/action', action).then((response: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                reject(e);
            });
        });
        return updateActionPromise;
    }

    deleteAction(id: string): Promise<boolean>{
        const deleteActionPromise = new Promise<boolean>((resolve, reject) => {
            api.delete('workflows/action/' + id).then((res: AxiosResponse<{}>) => {
                resolve(true);
            }).catch(e => {
                console.log(e);
            })
        });
        return deleteActionPromise;
    }
}

export default WorkflowService;