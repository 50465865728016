/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from 'react';
import './WorkflowsComponent.css';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import AuthStore from '../../../stores/AuthStore';
import WorkflowService from '../../../services/WorkflowService';
import WorkflowStore from '../../../stores/WorkflowStore';
import IWorkflow from '../../../entities/Workflow';
import { observer } from 'mobx-react-lite';
import SearchStore from '../../../stores/SearchStore';
import { Link } from 'react-router-dom';

const WorkflowsComponent: FC = () => {

    const authStore = useContext(AuthStore);
    const searchStore = useContext(SearchStore);
    const workflowStore = useContext(WorkflowStore);
    const workflowService = new WorkflowService();

    useEffect(() => {
        workflowService.getWorkflows().then((workflows: IWorkflow[]) => {
            workflowStore.setWorkflows(workflows);
        });
    }, [workflowStore]);

    return (
        <Row>
            <Col md="12">
                <Link to="workflow/new" className="btn btn-info float-right">Νέο Πρόγραμμα</Link>
                <h1>Προγράμματα</h1>
            </Col>
            {
                Array.from(workflowStore.workflows.values()).filter((w: IWorkflow) => {
                    return w.name?.includes(searchStore.search) || w.description?.includes(searchStore.search)
                }).map((w: IWorkflow) => {
                    let endDate = new Date(w.endingDate);
                    return (
                        <Col md={12} key={w.id} style={{marginTop: '20px'}}>
                            <Card>
                                <CardHeader className={ endDate.getTime() < Date.now() ? 'bg-danger' : 'bg-info'}>
                                    <h3>{w.name}</h3>
                                </CardHeader>
                                <CardBody>
                                    <p>{w.description}</p>
                                </CardBody>
                                <CardFooter>
                                    <Link to={'workflow/' + w.id} className="btn btn-primary float-right">Επεξεργασία</Link>
                                </CardFooter>
                            </Card>
                        </Col>           
                    )
                })
            }
        </Row>
    )
};

export default observer(WorkflowsComponent);